@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
}

/* Onboarding Container */
.onboarding-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  min-height: 40vh;
  margin-bottom: 150px;
  box-sizing: border-box;
}

/* Navigation for steps */
.step-nav {
  display: flex;
  z-index: 100;
  opacity: 0.8;
  gap: 20px;
  margin-bottom: 30px;
  justify-content: center;
  position: absolute;
  top: 28px; /* O něco níž */
  left: 6%; /* Posunutí doprava */
}

.step-btn {
  background-color: #9e9e9e;
  border: none;
  padding: 10px 30px;
  border-radius: 25px;
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.24));
  cursor: not-allowed;
  color: #fff;
  font-weight: bold;
  font-size: 16px;
  transition: background-color 0.3s;
  transition: all 0.3s ease-in-out;
}

.step-btn:hover {
  transform: scale(1.05);
    background: #161616;
    color: #fff;
    border: 0.5px solid rgba(255, 255, 255, 0.288);
}

.step-btn.active {
  cursor: pointer;
  backdrop-filter: blur(5px) !important;
  background-color: #000000;
  color: #ffffff;
}

.step-btn.completed {
  background-color: #cfcfcf;
  color: #505050;
  backdrop-filter: blur(5px) !important;
  opacity: 0.7;
  cursor: pointer;
}

/* Video and Form Step Styling */
.video-step,
.form-step {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  width: 100%;
}

h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #000000;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.041));
  font-size: large;
  font-weight: 800;
}

/* Instructions */
.instructions {
  text-align: left;
  width: 100%;
  max-width: 500px;
  margin-top: 20px;
}

.instruction-step {
  display: flex;
  width: 500px;
  gap: 10px;
  align-items: center;
  margin-bottom: 20px;
  border: 1px solid rgba(209, 209, 209, 0.856);
  background: linear-gradient(50deg, #ffffff, #ffffffbe, #ffffff);
  padding: 25px;
  backdrop-filter: blur(10px); /* The blur effect */
  border-radius: 25px;
  flex-direction: column;
  box-shadow: 0 0 20px rgba(187, 187, 187, 0.026);
  justify-content: flex-start;
}

.instruction-step span {
  font-size: 18px;
  background: linear-gradient(50deg, #ffffff, #ffffffbe, #ffffff);
  border-radius: 50%;
  color: #000;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.382) inset;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}

.instruction-text {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.instruction-text p {
  margin: 0;
  flex: 1;
}

.span1 {
  align-items: left !important;
  float:left;
}

.pform {
  color: #000;
}

.instruction-img {
  display: block;
  max-width: 100%;
  height: 500px;
  border: 1px solid rgba(221, 221, 221, 0.842);
  height: auto;
  border-radius: 25px;
  margin-top: 10px;
  transition: all 0.3s ease-in-out;
}

.instruction-img:hover {
scale: 1.1;
}
.onboradingin {
  background: #ffffff;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.182) inset;
  color: #000;
  border: none;
  padding: 12px 15px;
  width: 100%;
  border-radius: 25px;
  border: 1px solid rgb(179, 179, 179);
  backdrop-filter: blur(10px);
  font-size: 14px;
  transition: all 0.3s ease;
}

.onboradingin:hover {
  color: #000;
  background: #ffffff;
  border: 1px solid rgb(179, 179, 179);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.182) inset;
}

.finalp {
  color: black;
}
.onboradingin:focus {
  color: #000;
  background: #ffffff;
  border: 1px solid rgb(179, 179, 179);
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.182) inset;
  outline: none;
  padding: 12px 15px;
  width: 100%;
  border-radius: 25px;
  font-size: 14px;
  transition: all 0.3s ease;
}


/* Input Styling */
.onboradingin2 {
  background: linear-gradient(50deg, #ffffff, #dfdfdf);
  box-shadow: 0 0 20px rgba(216, 216, 216, 0.082) inset;
  color: rgb(0, 0, 0);
  text-align: center;
  border: 1px solid rgb(216, 216, 216);
  padding: 12px 15px;
  width: 82%;
  border-radius: 25px;
  backdrop-filter: blur(10px);
  font-size: 14px;
}


.ip-address button {
  color: #ffffff;
  width: 85%;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.182) inset;
  padding: 12px;
  filter: drop-shadow(0px 0px 15px rgba(92, 92, 92, 0.153));
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  background: #000000;
  background-size: 400% 400%;
  backdrop-filter: blur(10px);
  border-radius: 50px;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  height: 40px;
  animation: h1anim 0.5s ease-in-out !important;
  transition: all 0.3s ease;
}

.ip-address button:hover {
  color: #000;
  transform: scale(1.02);
  border: 1px solid rgb(179, 179, 179);
  background: #fdfdfd;
}

.onboradingin {
  margin-top: 20px;
}

.onboradingin::placeholder {
  color: #000000;
}

label {
  color: #000;
}

/* IP Address Section */
.ip-address {
  display: flex;
  gap: 10px;
  margin-top: 10px;
  align-items: center;
  width: 100%;
}

.ip-address .onboradingin {
  width: 70%;
  padding: 12px;
}

/* Notification styling */
.notification {
  background-color: #fafafab7;
  color: rgb(0, 0, 0);
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.182) inset;
  filter: drop-shadow(0px 0px 15px rgba(206, 206, 206, 0.862));
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  position: fixed;
  font-size: 16px;
  font-weight: 400;
  backdrop-filter: blur(5px);
  bottom: 20px;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 500px;
  z-index: 1000;
  animation: slideIn 0.5s ease-in-out, slideOut 0.5s ease-in-out 2.5s;
}

@keyframes slideIn {
  from {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
  to {
    transform: translate(-50%, 0);
    opacity: 1;
  }
}

@keyframes slideOut {
  from {
    transform: translate(-50%, 0);
    opacity: 1;
  }
  to {
    transform: translate(-50%, 100%);
    opacity: 0;
  }
}

/* Video Container and Play Button */
.video-container {
  text-align: center;
  filter: drop-shadow(0px 0px 15px rgba(253, 253, 253, 0.862));
  margin-bottom: 20px;
}

.custom-video {
  position: relative;
  display: inline-block;
  filter: drop-shadow(0px 0px 15px rgba(196, 196, 196, 0.862));
  border-radius: 25px;

  overflow: hidden;
}

.video-file {
  width: 40%;
  border: 5px solid rgba(160, 160, 160, 0.774);
  height: auto;
  filter: drop-shadow(0px 0px 15px rgba(253, 253, 253, 0.862));
  border-radius: 25px;
  cursor: pointer;
}

.play-button {
  position: absolute;
  top: 50%;
  left: 50%;
  filter: drop-shadow(0px 0px 15px rgba(253, 253, 253, 0.862));
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.play-button img {
  width: 80px;
  height: auto;
  opacity: 1;
  filter: saturate(1);
  transition: all 0.2s ease;
  animation: pulse2 3s infinite ease-in-out;
}

@keyframes pulse2 {
  0% {
    transform: scale(1);
    filter: saturate(0.0);
  }
  50% {
    transform: scale(1.02);
    filter: saturate(0.5);
  }
  100% {
    transform: scale(1);
    filter: saturate(0.0);
  }
}


.play-button img:hover {
  animation: none;
    filter: saturate(1.5);
    scale: 1.1; 
    opacity: 1;
}

.video-timeline {
    margin: auto;
    width: 40%;
    filter: drop-shadow(0px 0px 15px rgba(253, 253, 253, 0.862));
    height: 10px;
    background-color: #c7c7c7;
    cursor: pointer;
    border-radius: 25px;
    opacity: 0.9;
    margin-top: 10px;
  }
  
  .progress {
    height: 100%;
    border-radius: 25px;
    background: linear-gradient(50deg, #FFC8C8, #E4CCF9, #F3CFFF);
    box-shadow: 0 0 5px rgba(158, 158, 158, 0.582) inset;
  }
  
  .progress2 {
    height: 50%;
    opacity: 1;
    border-radius: 25px;
    background: linear-gradient(50deg, #FFC8C8, #E4CCF9, #F3CFFF);
    filter: blur(15px);
    box-shadow: 0 0 5px rgba(0, 0, 0, 0.582) inset;
  }
  
  .time-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
    max-width: 40%;
    margin: auto;
    margin-top: 5px;
  }
  
  .time-info span {
    font-size: 14px;
    color: #000000;
  }
  

  
  .large-image-container {
    position: absolute;
    top: 40%;
    left: 50%;
    transform: translate(-50%, -50%); /* Horizontální i vertikální zarovnání na střed */
    z-index: -100 !important; /* Obrázek bude pod videem */
    width: 100%; /* Přidáno pro lepší přizpůsobení */
    height: auto; /* Zachování poměru stran */
}

  
.large-image {
  position: fixed;
  top: 50%;
  left: 50%;
  width: 100vw; /* Celá šířka obrazovky */
  height: 100vh; /* Celá výška obrazovky */
  z-index: -100; /* Obrázek bude pod ostatním obsahem */
  opacity: 0.8; /* Poloprůhledný efekt */
  object-fit: cover; /* Obrázek se přizpůsobí bez deformace */
  transform: translate(-50%, -50%); /* Vystředění */
}


.large-image2 {
position: fixed;
top: 50%;
left: 50%;
width: 100vw; /* Celá šířka obrazovky */
height: 300vh; /* 100vh!!!!!!! */
z-index: -100; /* Obrázek bude pod ostatním obsahem */
opacity: 0.8; /* Poloprůhledný efekt */
object-fit: cover; /* Obrázek se přizpůsobí bez deformace */
transform: translate(-50%, -50%); /* Vystředění */
}


.custom-video .video-file {
  filter: grayscale(100%);
  transition: filter 0.3s ease-in-out;
}

.video-container.playing .video-file {
  filter: none; /* Remove the blur when the video is playing */
}

.fullscreen .video-file {
  filter: none; /* Remove blur when in fullscreen mode */
}

  

  .continue-btn,
.send-btn {
    color: #ffffff;
    width: 250px;
    padding: 10px;
    filter: drop-shadow(0px 0px 15px rgba(92, 92, 92, 0.153));
    font-family: "Roboto", sans-serif !important;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.182) inset;
    font-weight: 500 !important;
    font-size: 14px !important;
    background: #000000;
    background-size: 400% 400%;
    backdrop-filter: blur(10px);
    border-radius: 50px;
    border: none;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    max-width: 700px;
    margin-top: 15px;
    height: 40px;
    animation: h1anim 0.5s ease-in-out !important;
    transition: all 0.3s ease;
  }
  
  /* Animace gradientu pozadí */
  .continue-btn,
.send-btn {
    animation: backgroundShift 10s ease infinite;
  }
  
  @keyframes backgroundShift {
    0% { background-position: 0% 50%; }
    50% { background-position: 100% 50%; }
    100% { background-position: 0% 50%; }
  }
  
  /* Hover efekt s gradientem pod kurzorem */
  .continue-btn::after,
.send-btn::after {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    background: radial-gradient(circle at var(--x) var(--y), 
      rgba(255, 153, 102, 0.8), rgba(153, 204, 255, 0.8), rgba(255, 204, 255, 0.8), rgba(204, 255, 153, 0.8));
    border-radius: 50%;
    pointer-events: none;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.2s ease-out, width 0.3s ease, height 0.3s ease;
  }
  
  /* Při hoveru zobrazit a zvětšit efekt */
  .continue-btn:hover::after,
  .send-btn:hover::after {
    opacity: 1;
    width: 150px;
    height: 150px;
    transition: opacity 0.2s ease-in, width 0.5s ease, height 0.5s ease;
  }
  
  /* Hover transformace tlačítka */
  .continue-btn:hover,
  .send-btn:hover {
    transform: scale(1.02);
    background: #fdfdfd;
    color: black;
    border: 1px solid rgb(179, 179, 179);
  }

  .loader-container2 {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgb(0, 0, 0); /* Transparentní černé pozadí */
    display: flex;
    justify-content: center;
    backdrop-filter: blur(10px); /* Blurred background */
    align-items: center;
    z-index: 9999;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.3s ease-in-out;
  }
  
  .loader-container2.show {
    opacity: 1;
    pointer-events: all;
  }
  
  .loader2 {
    position: relative;
    width: 60px;
    height: 60px;
    border: 4px solid transparent;
    border-top: 4px solid #f6f3f7;
    border-right: 4px solid #d4d3d2;
    border-radius: 50%;
    animation: spin 1.2s linear infinite, rotateColor 2.5s ease-in-out infinite;
  }
  
  /* Vnější kruh s gradientem */
  .loader2:before {
    content: '';
    position: absolute;
    top: 5px;
    left: 5px;
    right: 5px;
    bottom: 5px;
    border: 4px solid transparent;
    border-top: 4px solid #f0f0f0;
    border-right: 4px solid #ebebeb;
    border-radius: 50%;
    animation: spin 1s reverse infinite;
  }
  
  /* Vnitřní kruh s gradientem */
  .loader2:after {
    content: '';
    position: absolute;
    top: 15px;
    left: 15px;
    right: 15px;
    bottom: 15px;
    border: 4px solid transparent;
    border-top: 4px solid #ffffff;
    border-right: 4px solid #f5f5f5;
    border-radius: 50%;
    animation: spin 1.5s linear infinite;
  }
  
  /* Animace rotace */
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
  /* Jemný přechod barev */
  @keyframes rotateColor {
    0%, 100% {
      border-top-color: #ececec;
      border-right-color: #c7c7c7;
    }
    50% {
      border-top-color: #f5f5f5;
      border-right-color: #ffffff;
    }
  }
  
  /* Animace pro fade-in a fade-out */
  .fade-in {
    opacity: 0;
    animation: fadeIn 0.3s forwards;
  }
  
  .fade-out {
    opacity: 1;
    animation: fadeOut 0.3s forwards;
  }
  
  @keyframes fadeIn {
    to {
      opacity: 1;
    }
  }
  
  @keyframes fadeOut {
    to {
      opacity: 0;
    }
  }
  

  .zzz-loader {
    font-size: 2rem;
    color: #000000;
    margin: 20px 0;
    text-align: center;
  }
  
  .zzz-loader span {
    display: inline-block;
    width: 15px;
    height: 15px;
    margin: 3px;
    background-color: #020202;
    border-radius: 50%;
    animation: bounce 1.8s infinite ease-in-out;
  }
  
  .zzz-loader span:nth-child(2) {
    animation-delay: 0.3s;
  }
  
  .zzz-loader span:nth-child(3) {
    animation-delay: 0.6s;
  }
  
  @keyframes bounce {
    0%, 70%, 100% {
      transform: scale(0);
    }
    50% {
      transform: scale(1.2);
    }
  }
  
  .fullscreen-info {
    font-size: 12px;
    color: rgb(0, 0, 0);
    text-align: center;
    margin-top: 10px;
  }
  

  .fullscreen-info2 {
    font-size: 12px;
    color: rgb(0, 0, 0);
    text-align: center;
    margin-top: -10px;
    margin-bottom: 50px;
  }

  @media screen and (max-width: 768px) {
    /* Obecné nastavení pro mobilní zařízení */
    body {
      font-size: 12px; /* Menší základní velikost písma */
    }
  
    .onboarding-container {
      margin: 0 auto; /* Centering the container horizontally */
      text-align: center; /* Ensuring content is centered */
      padding: 10px; /* Adding some padding */
      width: 90%; /* Restricting the width for better layout */
      box-sizing: border-box;
      margin-top: 150px; /* Upravení horního odsazení */
      margin-bottom: 100px; /* Upravení dolního odsazení */
    }
  
    /* Navigační tlačítka */
    .step-nav {
      margin-top: 155px !important; /* Upraveno pro správné zobrazení */
      left: auto; /* Zrušení posunu do strany */
      width: 90%; /* Přizpůsobení šířky */
      justify-content: center; /* Zarovnání na střed */
    }
  
    .step-btn {
      padding: 8px 10px; /* Zmenšené tlačítko */
      font-size: 10px; /* Zmenšená velikost textu */
    }
  
    /* Formulářové vstupy */
    .onboradingin {
      width: 300px;
      margin: 2px auto; /* Centering form elements */
      padding: 10px; /* Adding padding for better usability */
      box-sizing: border-box; /* Ensuring consistent box sizing */
      font-size: 12px;
    }
    .onboradingin:focus {
      width: 300px;
      margin: 2px auto; /* Centering form elements */
      padding: 10px; /* Adding padding for better usability */
      box-sizing: border-box; /* Ensuring consistent box sizing */
      font-size: 12px;
    }

    .onboradingin2:focus {
      margin: 2px auto; /* Centering form elements */
      padding: 5px; /* Adding padding for better usability */
      box-sizing: border-box; /* Ensuring consistent box sizing */
      font-size: 12px;
    }
  
    .onboradingin2 {
      margin: 2px auto; /* Centering form elements */
      padding: 5px; /* Adding padding for better usability */
      box-sizing: border-box; /* Ensuring consistent box sizing */
      font-size: 12px; /* Menší text */
    }
  
    label {
      font-size: 12px; /* Zmenšení velikosti písma */
      margin-bottom: 5px; /* Mezera mezi label a inputem */
    }
  
    .ip-address {
      flex-direction: column; /* Vertikální zarovnání */
      gap: 10px; /* Mezera mezi prvky */
      align-items: stretch; /* Zarovnání na šířku */
    }
  
    .ip-address .onboradingin {
      width: 100%; /* Vstup zabírá celou šířku */
    }
  
    .ip-address button {
      margin: 10px auto; /* Centering the button */
      width: 85%; /* Making the button full width */
      height: 40px; /* Ensuring consistent button height */
      font-size: 10px; /* Standardizing the text size */
    }
  
    /* Oznámení */
    .notification {
      width: 90%; /* Šířka přizpůsobena obrazovce */
      padding: 10px; /* Menší padding */
      font-size: 12px; /* Zmenšené písmo */
    }
  
    /* Instrukce */
    .instructions {
      width: 100%; /* Šířka přizpůsobená mobilnímu zařízení */
      margin-top: 10px; /* Zmenšená mezera nahoře */
    }
  
    .instruction-step {
      width: 95%; /* Přizpůsobeno menším obrazovkám */
      margin: 0 auto 20px auto; /* Centering each instruction block */
      text-align: center; /* Ensuring the text is centered */
      padding: 15px; /* Menší padding */
      font-size: 12px; /* Zmenšená velikost textu */
    }
  
    .instruction-img {
      margin: 10px auto; /* Centering the image */
      width: 100%; /* Obrázek zabírá celou šířku */
      height: auto; /* Automatické přizpůsobení výšky */
    }
  
    /* Tlačítka */
    .continue-btn,
    .send-btn {
      width: 100%; /* Tlačítka zabírají celou šířku */
      font-size: 12px; /* Zmenšená velikost textu */
      padding: 10px; /* Upravený padding */
      margin-top: 15px;
    }
  
    /* Video a časová osa */
    .video-container {
      width: 100%; /* Plná šířka pro video */
    }

    .large-image-container {
      display: none;
    }
  
    .video-file {
      margin: 10px auto;
      width: 90%; /* Přizpůsobení šířky videa */
      border-width: 3px; /* Zmenšený rámeček */
    }
  
    .video-timeline {
      width: 90%; /* Šířka přizpůsobená obrazovce */
      height: 8px; /* Menší výška časové osy */
    }
  
    .time-info {
      max-width: 90%; /* Přizpůsobení šířky informací o čase */
    }
  
    .time-info span {
      font-size: 10px; /* Zmenšená velikost textu */
    }
  
    /* Pozadí */
    .large-image {
      position: fixed;
      top: 0; /* Zarovnání pozadí nahoře */
      left: 0; /* Zarovnání pozadí vlevo */
      width: 100vw; /* Pokrytí celé šířky obrazovky */
      height: 100vh; /* Pokrytí celé výšky obrazovky */
      z-index: -1; /* Zajištění pozadí za obsahem */
      object-fit: cover; /* Zajistí správné měřítko obrázku */
    }
  
    .large-image2 {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      height: 100vh;
      z-index: -1;
      object-fit: cover;
    }
  }