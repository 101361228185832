@import url("https://fonts.googleapis.com/css2?family=Outfit:wght@100..900&display=swap");
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

* {
  font-family: "Roboto", sans-serif;
  font-weight: 300;
  font-size: 14px;
}

body {
  font-optical-sizing: auto;
  font-weight: 400;
  font-style: normal;
  padding: 0;
  display:flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh; /* Zajist�, �e body zab�r� celou v��ku obrazovky */
  text-align: center;
  position: relative;
}

.login-background {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 50%;
  background-image: url('../../assets/Frame27.svg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  z-index: -1;
}

.buttonshow {
  color: #ffffff;
  outline: none;
  width: 35%;
  margin-right: 5px;
  padding: 12px 12px;
  background: #1e1e20;
  margin: 10px 0;
  border: 1px solid #ccc;
  border-radius: 50px; /* Zaoblen� hrany */
  border: none;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  z-index: 1000;
  cursor: pointer;
  max-width: 700px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonshow:hover {
  background: #555658;
}

.buttonreset {
  color: #ff0000;
  outline: none;
  width: 50%;
  margin-right: 10px !important;
  padding: 12px 12px;
  background: rgba(27, 23, 27, 0);
  margin: 10px 0;
  z-index: 1000;
  border: 1px solid #ccc;
  border-radius: 50px; /* Zaoblen� hrany */
  border: 0.2px solid rgba(255, 255, 255, 0.438);
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  animation: fade-in 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}



@keyframes backgroundAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}


/* Pro zajímavější vnější stín */

/* Animace gradientu */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Animace záře */
@keyframes glow {
  0% {
    box-shadow:
      0 0 15px rgba(24, 24, 24, 0.3), /* Mírnější vnější záře */
      inset 0 0 40px rgba(0, 0, 0, 0.4); /* Mírnější vnitřní záře */
  }
  100% {
    box-shadow:
      0 0 50px rgba(17, 17, 17, 0.6), /* Silnější vnější záře */
      inset 0 0 120px rgba(0, 0, 0, 0.6); /* Silnější vnitřní záře */
  }
}



.google-container {
  position: relative;
  display: inline-block; /* Ensures the container size matches the button */
}

.spinner2 {
  border: 2px solid rgba(255, 255, 255, 0.3);
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin2 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
  
  position: absolute;
  top: 40%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@keyframes spin2 {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}


#buttonswitch {
  max-width: 600px;
}
.invalid {
  border-color: red; /* Barva ohrani�en� pole */
  background-color: #ffe6e6; /* Barva pozad� pole */
}

.buttonlog {
  color: #ffffff;
  outline: none;
  width: 35%;
  margin-right: 5px !important;
  padding: 12px 12px;
  background: #1e1e20;
  backdrop-filter: blur(10px);
  margin: 10px 0;
  border-radius: 50px; /* Zaoblen� hrany */
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  border: none;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonlog:hover {
  background: #555658;
}

.buttonlog2 {
  color: #ffffff;
  outline: none;
  width: 450px;
  margin-right: 5px !important;
  padding: 12px 12px;
  background: #1e1e20;
  backdrop-filter: blur(10px);
  margin: 10px 0;
  border-radius: 50px; /* Zaoblen� hrany */
  box-sizing: border-box;
  border: none;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonlog2:hover {
  background: #555658;
}

.buttonlog_loading {
  color: #e8ecee;
  width: 250px;
  padding: 12px;
  margin: 20px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  background: #1e1e20;
  background-size: 400% 400%;
  backdrop-filter: blur(10px);
  border-radius: 50px;
  border: none;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  margin-top: 50px;
  height: 40px;
  animation: h1anim 0.5s ease-in-out !important;
  transition: all 0.3s ease;
}

.google-login {
  display: flex;
  justify-content: center;
  align-items: center; /* Ensure vertical centering */
  transition: all 0.3s ease;
  animation: h1anim 0.5s ease-in-out;
  width: 100%; /* Take full width */
  height: 50px; /* Adjust height as needed */
}

.google-login:hover {
  transform: scale(1.05);
}

@media (max-width: 768px) { /* Mobile-first styling */
  .google-login {
    justify-content: center;
    margin: auto;
    padding: 0 10px; /* Add some padding to prevent edge overflow */
  }
}

/* Animace gradientu pozadí */
.buttonlog_loading {
  animation: backgroundShift 10s ease infinite;
}

@keyframes backgroundShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* Hover efekt s gradientem pod kurzorem */
.buttonlog_loading::after {
  content: '';
  position: absolute;
  width: 50px;
  height: 50px;
  background: radial-gradient(circle at var(--x) var(--y), 
    rgba(255, 153, 102, 0.8), rgba(153, 204, 255, 0.8), rgba(255, 204, 255, 0.8), rgba(204, 255, 153, 0.8));
  border-radius: 50%;
  pointer-events: none;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s ease-out, width 0.3s ease, height 0.3s ease;
}

/* Při hoveru zobrazit a zvětšit efekt */
.buttonlog_loading:hover::after {
  opacity: 1;
  width: 150px;
  height: 150px;
  transition: opacity 0.2s ease-in, width 0.5s ease, height 0.5s ease;
}

/* Hover transformace tlačítka */
.buttonlog_loading:hover {
  transform: scale(1.05);
  background: #555658;
}




.buttonlog_loading3 {
  color: #ffffff;
  outline: none;
  width: 145px;
  margin-left: 5px !important;
  padding: 12px 12px;
  background: #1e1e20;
  backdrop-filter: blur(10px);
  margin: 10px 0;
  border-radius: 50px; /* Zaoblen� hrany */
  border: none;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 150px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonlog_loading3:hover {
  background: #555658;
}

.buttonlog_loading3:disabled {
  cursor: not-allowed;
  background: #1e1e20;
  backdrop-filter: blur(10px);
  border-color: none;
}

.buttonlog_loading2 {
  color: #ffffff;
  outline: none;
  width: 300px;
  margin-left: 5px !important;
  padding: 12px 12px;
  background: #1e1e20;
  backdrop-filter: blur(10px);
  margin: 10px 0;
  border-radius: 50px; /* Zaoblen� hrany */
  border: none;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonlog_loading2:hover {
  background: #555658;
}

.buttonlog_loading2:disabled {
  cursor: not-allowed;
  color: #0000008a;
  background: rgba(150, 150, 150, 0.181);
  backdrop-filter: blur(10px);
  border: none;
}

.buttonlog_loading4 {
  color: #ffffff;
  outline: none;
  width: 315px;
  margin-left: 5px !important;
  padding: 12px 12px;
  background: #1e1e20;
  backdrop-filter: blur(10px);
  margin: 10px 0;
  border-radius: 50px; /* Zaoblen� hrany */
  border: none;
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonlog_loading4:hover {
  background: #555658;
}

.buttonlog_loading4:disabled {
  cursor: not-allowed;
  color: #ffffff60;
  background: rgba(150, 150, 150, 0.181);
  backdrop-filter: blur(10px);
  border: none;
}

.buttonlog_loading5 {
  color: #ffffff;
  outline: none;
  width: 315px;
  margin-right: 5px !important;
  padding: 12px 12px;
  background: rgba(0, 0, 0, 0.181);
  backdrop-filter: blur(10px);
  margin: 10px 0;
  border-radius: 50px; /* Zaoblen� hrany */
  border: 0.2px solid rgba(255, 255, 255, 0.438);
  box-sizing: border-box;
  transition: all 0.3s;
  margin-top: 20px;
  overflow: hidden;
  cursor: pointer;
  max-width: 700px;
  animation: h1anim 0.9s ease-in-out;
  transition: background 0.3s, border-color 0.3s; /* P�id�na transition pro background a border-color */
}

.buttonlog_loading5:hover {
  background: linear-gradient(to right, rgba(255, 255, 255, 0.14), rgba(255, 255, 255, 0.037));
  border-color: rgba(255, 255, 255, 0.438); /* Zm�na barvy ohrani�en� na hover */
}

.buttonlog_loading5:disabled {
  cursor: not-allowed;
  color: #ffffff60;
  background: rgba(150, 150, 150, 0.181);
  backdrop-filter: blur(10px);
  border-color: rgba(177, 176, 177, 0.527); /* Zm�na barvy ohrani�en� na hover */
}

.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.button-row {
  display: flex;
  gap: 5px;
}


.spinner {
  border: 2px solid rgba(233, 233, 233, 0.3);
  border-top: 2px solid #ffffff;
  border-radius: 50%;
  width: 15px;
  height: 15px;
  animation: spin 1s linear infinite;
  display: inline-block;
  vertical-align: middle;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.inputlog {
  color: #ffffff;
  outline: none;
  width: 100%;
  padding: 12px 12px;
  background: rgba(31, 32, 35, 0.544);
  margin: 10px 0;
  border: 1px solid #ccc;
  -webkit-backdrop-filter: blur(1px); /* P�id�n efekt rozmaz�n� pozad� pro Safari 9+ a Safari na iOS 9+ */
  backdrop-filter: blur(1px); /* P�id�n efekt rozmaz�n� pozad� */
  border-radius: 50px; /* Zaoblen� hrany */
  border: none;
  box-sizing: border-box;
  transition: all 0.3s;
}




.inputlog:focus:not(:-webkit-autofill) {
  background: linear-gradient(135deg, #fffeff6e, #ffa789d8, #ed98f07e, #cfcef59a, #f0bcfd, #f8747f98, #f7f7f75d);
  background-size: 300% 300%;
  animation: gradientAnimation 12s ease infinite;
  transition: background 0.3s ease-in-out;
  position: relative;
  color: #070707;
  z-index: 1;
  overflow: hidden;
  box-shadow: inset 0 0 20px rgba(92, 92, 92, 0.659), /* Černý inset shadow */
              inset 0 0 30px rgba(235, 206, 248, 0.4), /* Další vrstva pro více hloubky */
              0 4px 6px rgba(229, 216, 243, 0.075);      /* Externí shadow pro lehkou vnější hloubku */
}

/* Definování vnitřního glow efektu (aura) */
.inputlog:focus:not(:-webkit-autofill)::before {
  content: "";
  position: absolute;
  top: 0;
  left: -100%;
  width: 200%;
  color: #070707;
  height: 100%;
  background: radial-gradient(circle, rgba(255,255,255,0.4), transparent 60%);
  animation: auraGlow 6s linear infinite;
  z-index: 0;
}

/* Animace pro základní gradient */
@keyframes gradientAnimation {
  0% {
    background-position: 0% 50%;
  }
  25% {
    background-position: 50% 100%;
  }
  50% {
    background-position: 100% 50%;
  }
  75% {
    background-position: 50% 0%;
  }
  100% {
    background-position: 0% 50%;
  }
}

/* Animace pro pohybující se aura glow */
@keyframes auraGlow {
  0% {
    left: -100%;
  }
  50% {
    left: 50%;
  }
  100% {
    left: 100%;
  }
}





.inputlog:hover {
  box-shadow: rgba(255, 255, 255, 0.089) 0px 50px 5px -5px inset,
    rgba(0, 0, 0, 0.11) 0px 30px 50px -30px,
    rgba(255, 255, 255, 0.167) 0px -2px 6px 0px inset;
}

@keyframes fadeInOut {
  0% { opacity: 0; }
  10% { opacity: 1; }
  90% { opacity: 1; }
  100% { opacity: 0; }
}

.notification {
  animation: fadeInOut 2s ease-in-out forwards;
}

::placeholder {
  color: rgb(255, 255, 255);
}

.plog {
  font-size: small;
  color: #000000;
  animation: h1anim 0.5s ease-in-out;
}

.h1log {
  font-size: 70px;
  font-weight: bold;
  margin-bottom: 60px;
  letter-spacing: -3px;
  color: transparent;
  background: linear-gradient(90deg, rgb(0, 0, 0), rgb(0, 0, 0), rgba(24, 20, 15, 0.979));
  -webkit-background-clip: text;
  background-clip: text;
  animation: h1anim 0.5s ease-in-out;
}

@keyframes fadeInSlide {
  0% {
    opacity: 0;
    transform: translateY(50px); /* Slide up */
  }
  60% {
    opacity: 1; /* Fade in */
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

/* Z�kladn� nastaven� stylu pro scroll bar */
::-webkit-scrollbar {
  width: 0.1px; /* ���ka scroll baru */
}

/* Track - dr�ka, po kter� se pohybuje scroll bar */
::-webkit-scrollbar-track {
  background-color: #ffffff; /* Barva pozad� */
}

/* Handle - samotn� scroll bar */
::-webkit-scrollbar-thumb {
  background-color: #070707; /* Barva scroll baru */
  border-radius: 6px; /* Obl� rohy */
}

/* Pokud u�ivatel najede na scroll bar */
::-webkit-scrollbar-thumb:hover {
  background-color: #272727; /* Barva scroll baru p�i najet� my�i */
}

.googlelog {
  margin: 20px 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 25px !important;
  animation: googleanim 1.3s ease-in-out;
}


@keyframes googleanim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 0.001;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes h1anim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 0.8;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.verify-form-card-input-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  text-align: center;
}

.verify-form-card-input {
  all: unset;
  color: white !important;
  letter-spacing: 2rem;
  font-size: 50px;
}

.verify-form-card-input::placeholder {
  color: #ffffff !important;
}

.input-fields-container {
  padding: 25px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: hidden;
  color: #2e2e2e;
  border-radius: 15px;
}

.input-fields {
  display: flex;
  justify-content: space-between;
  gap: 5px;
}

.input-fields input {
  height: 60px;
  width: 60px;
  border: 0.5px solid rgba(253, 253, 253, 0.603) ;
  outline: none;
  text-align: center;
  font-size: 1.5rem;
  color: white;
  background: rgba(20, 20, 20, 0.801);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 15px;
}


.input-fields input:focus {
  background: rgba(10, 10, 10, 0.842) !important;
  border-color: rgba(255, 255, 255, 0.527) !important;
  box-shadow: inset 5px 5px 10px rgba(36, 36, 36, 0.15);
  color: #ffffff;
  transition: 0.5s;
}