/* Container for status */
.statusContainer {
  position: fixed;
  z-index: 10000;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.582) inset;
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.462));
  bottom: 50px;
  right: 20px; /* Closer to the right edge */
  width: 280px;
  background-color: #111113;
  color: white;
  border-radius: 25px;
  overflow: hidden;
  transition: transform 0.3s ease;
  transform: translateY(100%); /* Hidden by default */
}


.statusContainer.open {
  transform: translateY(0); /* Slide up */
  bottom: 30px;
}

/* Circle button for opening the statusContainer */
.statusOpener {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 50px;
  height: 50px;
  background-color: #1d1f20b2;
  color: white;
  border-radius: 50%; /* Makes it a circle */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.5rem; /* Larger font for question mark */
  cursor: pointer;
  z-index: 10001; /* Ensure it's above the overlay */
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;
}

.statusOpener:hover {
  background-color: #2b2d2f; /* Slightly darker on hover */
}

/* Header part for status */
.statusHeader {
  display: flex;
  align-items: center;
  padding: 10px;
  cursor: pointer;
  background-color: #020202;
}

.statusTitle {
  margin-left: 10px;
  font-size: 1rem;
  font-weight: bold;
}

.dot {
  width: 15px;
  height: 15px;
  border-radius: 50%;
  animation: pulse 2s infinite ease-in-out;
}

.onlineDot {
  background-color: #20f820b7; /* Green for online */
}

.offlineDot {
  background-color: #a8a8a8; /* Red for offline */
}

/* Details for uptime */
.statusDetails {
  padding: 15px;
  background-color: #1d1f2062;
  border-top: 1px solid #333;
}

.statusRow {
  margin-bottom: 15px;
}

.statusService {
  font-size: 0.9rem;
  margin-bottom: 5px;
}

.averageUptime {
  font-size: 0.8rem;
  color: #bdc3c7;
  margin-bottom: 10px;
}

/* Graph with 30 days */
.uptimeGraph {
  display: flex;
}

.dayBox {
  width: 10px;
  height: 25px;
  margin-right: 1px;
  background-color: #7f8c8d; /* Default gray for missing uptime */
  transition: background-color 0.2s ease;
  position: relative;
}

.dayBox:first-child {
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}

.dayBox:last-child {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}

.dayBox:hover {
  cursor: pointer;
  background-color: #242323; /* Highlight on hover */
}

.fullUptime {
  background-color: #20f820b7; /* Green for full uptime */
}

.partialUptime {
  background-color: #ffa51fb7; /* Orange for partial downtime */
}

.fullDowntime {
  background-color: #fa341eb7; /* Red for full downtime */
}

/* Popup for hover */
.popup {
  position: absolute;
  background-color: rgba(26, 27, 31, 0.9);
  color: rgb(182, 182, 182);
  padding: 5px;
  border-radius: 5px;
  font-size: 0.8rem;
  transform: translate(-50%, -50%);
  pointer-events: none; /* Prevents the popup from blocking other interactions */
}

.popupService,
.popupDay,
.popupDetail {
  margin-bottom: 5px;
}

/* Dark overlay background */
.overlay2 {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000; /* Below the status container */
}

/* Show overlay when statusContainer is open */
.statusContainer.open ~ .overlay2 {
  display: block;
}

/* Mobile styles */
@media (max-width: 768px) {
  .statusContainer {
    position: fixed;
    width: 40px;
    height: 40px;
    bottom: 80px; /* Move higher from the bottom */
    right: 20px; /* Keep it in the bottom right corner */
    border-radius: 50%; /* Circular container */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1001; /* Ensure it's on top */
    transition: all 0.5s ease; /* Smooth transition */
  }

  .statusContainer.open {
    background-color: #000000;
    width: 75%; /* Full width when open */
    height: auto;
    bottom: 30px; /* Keep it higher from the bottom */
    left: 50%; /* Center when open */
    right: auto; /* Remove the right positioning */
    border-radius: 20px; /* Reset border-radius for open state */
    display: block;
    z-index: 1001; /* Ensure it's on top */
    animation: slideInFromBottomRight 0.1s forwards; /* Use only animation */
    /* Removed transition to prevent overlap */
  }

  @keyframes slideInFromBottomRight {
    from {
      opacity: 0%;
      transform: translateX(50%) translateY(100%); /* Start from bottom-right off-screen */
    }
    to {
      transform: translateX(-50%) translateY(0); /* End at the centered position */
    }
  }

  .statusHeader {
    padding: 3px;
    background-color: #2b2d2f;
    justify-content: center;
    background-color: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
  }

  /* Only show the "klikni zde pro skrytí" when the container is open */
  .statusContainer.open .statusHeader::after {
    content: "Click anywhere to hide";
    font-size: 0.8rem;
    color: white;
    position: absolute;
    bottom: 9px;
    left: 50%;
    transform: translateX(-50%);
    white-space: nowrap;
  }

  /* Hide the after content when the container is closed */
  .statusHeader::after {
    content: "";
  }

  .dot {
    width: 20px; /* Larger dot */
    height: 20px;
  }

  /* Hide the dot when the container is open */
  .statusContainer.open .dot {
    background-color: #ffffff00; /* Transparent when open */
  }

  .statusTitle {
    display: none; /* Hide title in mobile closed state */
  }

  .statusDetails {
    padding: 15px;
  }
}
