.formlog {
    position: relative;
    padding: 40px;
    max-width: 800px;
    margin: auto;
    min-height: 400px;
    height: 550px;
    text-align: center;
    background:
      linear-gradient(135deg, rgb(255, 255, 255), rgb(243, 243, 243), rgb(255, 255, 255), rgba(255, 255, 255, 0.616),  rgba(255, 255, 255, 0.274)),
      url('../../assets/Frame27.svg'), linear-gradient(50deg, #ffffff, rgba(255, 255, 255, 0.459),  rgba(255, 255, 255, 0.986)),; // Gradient overlay
    background-size: cover; // Obrázek pokryje celý prvek
    background-position: center; // Obrázek bude vystředěný
    border-radius: 20px;
    opacity: 1;
    transition: all 0.2s ease;
    border: 1px solid #e9e9e9;
  
    &::before {
      content: '';
      position: absolute;
      top: -2px;
      left: -2px;
      right: -2px;
      bottom: -2px;
      border-radius: 10px;
      z-index: -3;
    }
  
    &::after {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background: inherit;
      border-radius: 15px;
      z-index: -1;
    }
  
    & > * {
      position: relative;
      z-index: 1;
    }
  
    &::after {
      opacity: 0.6;
      z-index: -2;
    }
  
    &::after {
      z-index: -1;
    }
  }
  