/* Keyframes pro animaci pulzování */
@keyframes pulse {
  0% {
    transform: scale(0.95);
    opacity: 0.7;
  }
  50% {
    transform: scale(1);
    opacity: 1;
  }
  100% {
    transform: scale(0.95);
    opacity: 0.7;
  }
}

/* Keyframes pro animaci postupného zmizení */
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

/* Styl kontejneru pro loader */
.loader-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column; /* Přidáváme sloupec */
  justify-content: center;
  align-items: center;
  background-color: #000000;
  z-index: 9999; /* Vysoký z-index */
  pointer-events: none;
}

/* Styl pro text beta verze */
.beta-text {
  color: #ffffff; /* Bílá barva textu */
  text-align: center; /* Zarovnání textu na střed */
  margin-top: 20px; /* Odsazení od loga */
}

/* Styl pro loader */
.loader {
  width: 100px;
  height: 100px;
  background-image: url('../../assets/logo_loading.png');
  background-size: cover;
  background-repeat: no-repeat;
  animation: pulse 2s ease-in-out infinite; /* Nekonečná pulzující animace trvající 2 vteřiny */
}

/* Aplikace animace zmizení */
.fade-out {
  animation: fadeOut 1s ease-out forwards; /* Zmizí a zůstane neviditelný */
}