/* MobileView.css */

/* Styl pro div, když je aplikace zobrazena na mobilních zařízeních */
.mobile-view {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  /* Styl pro zástupný obrázek zobrazený na mobilních zařízeních */
  .mobile-placeholder {
    max-width: 120%;
    height: auto;
    margin-bottom: 80px;
  }
  