@media only screen and (min-width: 600px) {
  .popupapp,
  .popupapp2 {
    display: none !important;
  }
}

.popupapp {
  z-index: 998;
  position: fixed;
  top: 0;
  left: 0;
  background: linear-gradient(to right, rgb(241, 241, 241), rgb(238, 238, 238));
  max-height: 10%;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  padding: 10px;
  box-sizing: border-box;
}

.loginappbig,
.loginappsmall {
  margin: 0;
  color: black;
  text-align: left;
}

.loginappbig {
  font-size: 18px;
  font-weight: 600;
}

.loginappsmall {
  font-size: 11px;
  font-weight: 400;
  color: rgb(59, 59, 59);
}

.buttonapp {
  margin: 5px;
  height: 35px;
  width: 60px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.829);
  color: rgb(0, 0, 0);
  border: 0.2px solid rgb(236, 236, 236);
}

.logoapp {
  width: 50px;
  height: auto;
}

.popupapp2 {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  max-height: 80%;
  background: linear-gradient(to right, rgb(241, 241, 241), rgb(238, 238, 238));
  padding: 10px 0;
  text-align: center;
  z-index: 9999;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.popupapp2 p {
  color: black;
}

.fadeout {
  opacity: 1;
  transition: opacity 0.5s ease-out;
}

.fadeout-hidden {
  opacity: 0;
}

.popupapp2-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  backdrop-filter: blur(2px);
  -webkit-backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.836);
  z-index: 999;
  pointer-events: auto;
}

p {
  color: #000 !important;
  margin: 15px; /* Pouze jeden margin pro p elementy */
}

.gif {
  border-radius: 25px;
  width: 45%;
  height: auto;
  margin-bottom: 10px;
}

.close-button {
  margin: 5px;
  padding: 15px 80px;
  border-radius: 50px;
  background-color: rgba(255, 255, 255, 0.829);
  color: rgb(0, 0, 0);
  border: 0.2px solid rgb(214, 214, 214);
}
