/* Styling pro header */
header {
  margin: auto;
  padding: 20px;
  padding-bottom: 0px;
  z-index: 999999999;
}


@keyframes h1anim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 0.8;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.logo {
  display: flex;
  align-items: baseline;
  animation: h1anim 0.2s ease-in-out;
  text-decoration: none;
  color: inherit;
  margin-right: auto; /* Aligns the logo to the left */
  max-width: 45px;
  max-height: 45px;
  margin-bottom: 15px;
  h6 {
    font-size: 15px;
  }
}

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.btn {
  color: #cfcfcf;
  width: 85%;
  z-index: 1000;
  filter: drop-shadow(0px 0px 25px rgba(204, 204, 204, 0.062));
  padding: 12px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  background: #19191dc4;
  border-radius: 50px;
  backdrop-filter: blur(2px);
  border: none;
  position: relative;
  overflow: hidden;
  margin-top: -20px;
  cursor: pointer;
  max-width: 100px;
  height: 40px;
  animation: h1anim 0.5s ease-in-out !important;
  transition: all 0.3s ease;
}

.btn:hover {
    transform: scale(1.05);
    background: #161616;
    color: #fff;
    border: 0.5px solid rgba(255, 255, 255, 0.288);
}

.countdown-notification {
  position: fixed;
  top: 0;
  left: 0;
  animation: h1anim 0.3s ease-in-out;
  width: 100%;
  height: 100%;
  background-color: #0b000c;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}