@media only screen and (max-width: 600px) {
  .formlog {
    background-size: cover;
    margin: auto;
    min-width: 95%;
    width: 100%;
    border-radius: 15px;
    margin-bottom: 5px;
  }

  .formlog::before {
    background-size: 250%;
  }

  .buttonlog_loading {
    width: 250px;
    margin: auto;
    margin-bottom: 15px;
    margin-top: 20px;
  }

  .h1log {
    margin-bottom: 80px !important;
  }

  .buttonlog {
    margin-top: 20px;
    width: 300px;
  }

  .buttonshow {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    margin-right: 15px;
  }

  .buttonreset {
    width: 100%;
    margin: auto;
    margin-top: 10px;
    margin-right: 5px;
  }

  .buttonlog2 {
    margin-top: 20px;
    width: 80%;
    margin-right: 0px;
    margin-bottom: 40px;
  }

  .input-fields-container {
    font-family: Helvetica, sans-serif;
    border: 2px solid var(--glow-color);
    padding: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    overflow: hidden;
    color: #2e2e2e;
    border-radius: 8px;
  }


  .input-fields input {
    height: 45px;
    width: 45px;
    border: 1px solid rgba(255, 255, 255, 0.603) ;
    outline: none;
    text-align: center;
    font-size: 1.5rem;
    color: white;
    background: rgba(31, 32, 35, 0.836);
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    border-radius: 10px;
  }

  .input-fields input:focus {
    background: rgba(212, 3, 184, 0.253);
    border-color: rgba(140, 0, 255, 0.527);
    box-shadow: inset 5px 5px 10px rgba(24, 2, 20, 0.15);
    transition: 0.5s;
  }

  .h1log {
    font-size: 40px;
    margin-bottom: 30px;
  }

  .inputlog {
    width: 280px;
    margin: auto;
    margin-bottom: 30px;
  }

  .formlog::before {
    filter: none;
  }

  #buttonswitch {
    max-width: 90%;
  }
}

.googlelog {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 20px 0;
}