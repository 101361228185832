@media only screen and (max-width: 764px) {
  #sidebar {
      max-width: 100%;
      padding: 10px;
  }

  .blue {
      max-width: 95%;
      margin: auto;
      padding: 10px;
  }

  .small {
      max-width: 90%;
      margin: auto;
      padding: 10px;
  }

  .img_logo {
      display: none !important;
  }

  .dataEntry {
      border-radius: 5px !important;
      padding-top: 5;
      background-size: cover;
  }

  .buttonin {
    padding: 8px 12px;
    font-size: small;
  }

  .copyButton {
    padding: 8px 12px;
    font-size: small;
  }

  .dataEntry_div2 {
      display: grid !important;
      grid-template-columns: 1fr 1fr !important;
      gap: 10px;
      margin-top: 15px;
      padding: 10px
  }

  .dataEntry:has(> .dataEntry_div2) {
      grid-template-rows: auto !important;
      grid-template-columns: 1fr !important;
      max-width: 100%;
  }

  .dataEntry_div2 > .h2in {
      margin: auto;
      text-align: center;
      font-size: 16px;
  }

  footer {
      padding: 10px !important;
      margin-bottom: 40px !important;
      background-color: #f1f1f1;
  }

  .icons img {
      margin: 5px !important;
      max-width: 40px;
  }

  [data-result="Profit"] {
    background-image: url('../../assets/profit.svg');
    background-size: 100%;
    border-radius: 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.795);
    background-position: center;
    
}

[data-result="Loss"] {
    background-image: url('../../assets/profit.svg');
    background-size: 100%;
    border-radius: 25px !important;
    border: 1px solid rgba(255, 255, 255, 0.795);
    background-position: center;
}

[data-type="Opened Long"], [data-type="Opened Short"] {
    background-image: url('../../assets/profit.svg');
    background-size: 130%;
    border: 1px solid rgba(255, 255, 255, 0.795);
    border-radius: 25px !important;
    background-position: center;
}

  .nav_footer {
      display: none !important;
  }

  #logofooter, .h1_footer {
      display: none !important;
  }

  .icons {
      flex-wrap: wrap !important;
      justify-content: center;
  }

  .beta-text {
      margin-left: 20px;
      margin-right: 20px;
      font-size: 14px;
      text-align: center;
  }

  header .logo {
      width: 50px;
      height: auto;
  }

  .landing_text_img {
      max-width: 100%;
      height: auto;
      margin: 10px auto;
  }

  footer {
      display: block !important;
      text-align: center;
      font-size: 12px;
  }
}
