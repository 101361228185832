.select-container {
    display: flex;
    align-items: center;
  }
  
  .select-label {
    font-size: 16px;
    font-weight: bold;
    color: #ffffff;
    margin-right: 15px; /* Prostor mezi label a select */
  }
  
  .custom-select-wrapper {
    position: relative;
    display: inline-block;
  }
  
  .coin-select {
    font-size: 12px;
    color: #333;
    appearance: none; /* odstraní nativní vzhled prohlížeče */
    outline: none;
    cursor: pointer;
  }
  
  .select-arrow {
    position: absolute;
    top: 50%;
    right: 15px;
    pointer-events: none;
    transform: translateY(-50%);
    font-size: 16px;
    color: #333;
  }
  
  .coin-select:focus {
    border-color: #007bff;
  }
  
  .coin-select:hover {
    border-color: #007bff;
  }
  
  