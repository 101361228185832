#fullscreen-status-container {
  position: relative;
  color: #ecf0f1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  text-align: center;
}

#fullscreen-status-header {
  display: flex;
  align-items: center;
  padding: 20px 40px;
  border-radius: 25px;
  margin-bottom: 50px;
}

#fullscreen-status-title {
  font-size: 2.2rem;
  font-weight: 600;
  color: #000000;
}

#status-dot {
  width: 24px;
  height: 24px;
  filter: saturate(150%);
  border-radius: 50%;
  animation: pulse 2s infinite ease-in-out;
}

#online-dot {
  background-color: #2ecc71;
}

#offline-dot {
  background-color: #e74c3c;
}

#status-details {
  width: 80%;
  max-width: 1000px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.status-row {
  width: 100%;
  margin-bottom: 30px;
  padding: 25px;
  background-color: #1c2229;
  border-radius: 50px;
  padding-bottom: 50px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.status-row:hover {
  transform: translateY(-5px);
}

.status-service {
  font-size: 1.6rem;
  font-weight: 500;
  margin-bottom: 15px;
}

.status-average-uptime {
  font-size: 1.2rem;
  color: #bdc3c7;
  margin-bottom: 20px;
}

.status-uptime-graph {
  display: flex;
  justify-content: center;
}

.uptime-day-box {
  width: 25px;
  height: 40px;
  filter: saturate(150%);
  margin-right: 2px;
  background-color: #95a5a6;
  transition: background-color 0.3s ease, transform 0.2s ease;
  animation: popUp 0.2s ease;
}

.uptime-day-box:first-of-type {
  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
}

.uptime-day-box:last-of-type {
  border-top-right-radius: 12px;
  border-bottom-right-radius: 12px;
}

.uptime-day-box:hover {
  background-color: #000000; /* Highlight on hover */
  transform: scale(1.1);
}

.full-uptime {
  background-color: #2ecc71;
}

.partial-uptime {
  background-color: #f39c12;
}

.full-downtime {
  background-color: #e74c3c;
}

#status-popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(44, 62, 80, 0.95);
  backdrop-filter: blur(8px);
  color: #ecf0f1;
  padding: 20px;
  border-radius: 25px;
  font-size: 1rem;
  z-index: 999;
  animation: fadeInUp 0.3s ease;
}

#popup-service,
#popup-day,
#popup-detail {
  margin-bottom: 5px;
}


@keyframes popUp {
  0% {
    opacity: 0;
    transform: scale(0.8) rotate(0deg);
    filter: saturate(0);
  }
  50% {
    opacity: 0.7;
    transform: scale(0.8) rotate(5deg);
    filter: saturate(10%);
  }
  70% {
    opacity: 0.9;
    transform: scale(0.95) rotate(-3deg);
    filter: saturate(10%);
    border-radius: 50px;
    margin-left: 3px;
    margin-right: 3px;
  }
  100% {
    opacity: 1;
    transform: scale(1) rotate(0deg);
    filter: saturate(250%);
  }
}

@media (max-width: 600px) {

  #fullscreen-status-container {
    width: 100vw; /* Nastavuje šířku na 100% viewportu */
    height: auto; /* Nastavuje výšku na 100% viewportu */
    margin: 0;
    padding: 0;
    top: 0;
    left: 0;
}

  
  #fullscreen-status-title {
    font-size: 15px;
  }

  .status-service {
    font-size: 20px;
  }

  .status-average-uptime {
    font-size: 12px;
  }

  #fullscreen-status-header {
    border-radius: 25px;
  }

  .status-row {
    width: 100%;
    margin-bottom: 30px;
    background-color: #1c2229;
    padding-bottom: 50px;
    border-radius: 25px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    box-sizing: border-box; /* Zajišťuje, že padding a border jsou zahrnuty do šířky */
  }
}


#status-dot {
  width: 24px;
  height: 24px;
  filter: saturate(150%);
  border-radius: 50%;
  animation: pulse 1.8s infinite ease-in-out;
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.2);
    opacity: 0.8;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
}

/* Jemnější efekt na graf uptime */
.uptime-day-box:hover {
  background-color: #3e3e3e; /* Jemné zvýraznění při najetí myší */
  transform: scale(1.05); /* O trochu menší zvětšení při hoveru */
  transition: transform 0.25s ease, background-color 0.2s ease; /* Jemná animace */
}

#status-popup {
  animation: fadeInUp 0.4s ease-out; /* Pomalejší a jemnější fade-in efekt */
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translate(-50%, calc(-50% + 30px));
  }
  100% {
    opacity: 1;
    transform: translate(-50%, -50%);
  }
}
