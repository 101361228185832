/* Global Styles */
* {
  color: #fff;
  margin: 0;
  padding: 0;
}


@keyframes h1anim {
  0% {
    opacity: 0;
    transform: translateY(50px);
  }
  50% {
    opacity: 0.8;
    transform: translateY(0);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

body {
  display: var(--body-display, flex); /* Výchozí hodnota je flex */
  font-family: Arial, sans-serif;
  text-align: center;
  background-image: url('../../assets/Frame27.svg');
  margin: 0;
}



.h1in {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #ffffff;
  animation: h1anim 0.3s ease-in-out;
}

.upper-text {
  animation: h1anim 0.2s ease-in-out;
  margin-top: 80px;
  margin-bottom: 100px; 
}

/* Data Entry Styles */


.signals-container {
  max-height: 80vh; /* Maximální výška kontejneru pro signály, aby byl viditelný scrollbar */
  overflow-y: auto; /* Vertikální scrollbar pro signály */
  padding-right: 10px; /* Aby nebyl scrollbar přes text */
  border-radius: 25px;
}

[data-result="Profit"] {
  background-image: url('../../assets/profit.svg');
  background-size: 100%;
  border: 1px solid rgba(255, 255, 255, 0.795);
  background-position: center;
}

[data-result="Loss"] {
  background-image: url('../../assets/loss.svg');
  background-size: 100%;
  border: 1px solid rgba(255, 255, 255, 0.795);
  background-position: center;
}

[data-type="Opened Long"], [data-type="Opened Short"] {
  background-image: url('../../assets/entry.svg');
  background-size: 130%;
  border: 1px solid rgba(255, 255, 255, 0.795);
  background-position: center;
}

/* Styl pro jednotlivé signály */

/* Styl scrollbaru */
.signals-container::-webkit-scrollbar {
  width: 5px; /* Šířka scrollbaru */
}

.signals-container::-webkit-scrollbar-thumb {
  background-color: #ffffff; /* Barva scrollbaru */
  border-radius: 10px; /* Zaoblení scrollbaru */
}

.signals-container::-webkit-scrollbar-track {
  background-color: #1e1e2f; /* Pozadí tracku scrollbaru */
}

.dataEntry h2 {
  font-size: 18px;
}

.dataEntry_div1 {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.singleRow {
  flex-direction: row;
  display: none;

}

.dataEntry_div2 {
  display: flex;
  flex-direction: column;
  align-items: center;
}


/* Style for the loader */
.loader-container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
  background-color: #f5f5f5;
}

/* Upper section background */
.upper_background {
  background-size: cover; /* Přizpůsobí obrázek velikosti */
  background-position: center; /* Centrovaný obrázek */
  padding: 20px;
  padding-top: 0 !important;
  color: white;
  text-align: center;
}

/* Stats Table */
.stats-table {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 20px 0;
}

.stats-header {
  display: flex;
  justify-content: space-around;
  width: 80%;
  padding: 10px;
  color: white;
  border-radius: 8px;
}





/* Navbar */
.navbar {
  background-color: #333;
  padding: 10px;
  text-align: center;
}

.navbar ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.navbar li {
  display: inline;
  margin-right: 20px;
}

.navbar a {
  color: white;
  text-decoration: none;
  font-size: 16px;
}

.navbar a:hover {
  text-decoration: underline;
}

.overview {
   /* Colors and background */
   background: linear-gradient(135deg, rgba(0, 0, 0, 0.966), rgba(0, 0, 0, 0.973), rgba(8, 8, 8, 0.966), rgba(4, 5, 7, 0.945), rgba(4, 4, 5, 0.966)); /* Gradient with blue and pink tint */
   backdrop-filter: blur(10px); /* Backdrop blur effect */
   -webkit-backdrop-filter: blur(10px); /* Safari support */
   border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for separation */
 
   /* Box shadow */
   box-shadow: 0 2px 15px rgba(0, 0, 0, 0.17); /* Deeper shadow effect */
  padding: 20px;
  border-radius: 25px;
  width: 350px;
  text-align: center;
}

.overview h2 {
  font-size: 24px;
  margin-bottom: 100px;
  color: #e0e0e0;
}

.overspan {
  font-weight: 800 !important;
  font-size: 62px !important;

}

.overh2 {
  font-weight: 800 !important;
  font-size: 40px !important;
}
.stat {
  font-size: 40px;
  font-weight: 900;
  background: linear-gradient(99deg, 
  #f0f0f0 0%, 
  #d3d3d3 15%, 
  #b8b8b8 40%, 
  #b9b9b9 60%, 
  #bbbbbb 75%, 
  #c7c7c7 90%, 
  #d8d8d8 100%);
  -webkit-background-clip: text;
  color: transparent;
}


.overview span {
  font-size: 48px;
  font-weight: bold;
  background: linear-gradient(99deg, 
  #f0f0f0 0%, 
  #d3d3d3 15%, 
  #b8b8b8 40%, 
  #b9b9b9 60%, 
  #bbbbbb 75%, 
  #c7c7c7 90%, 
  #d8d8d8 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.description {
  margin-bottom: 100px;
  font-size: 14px;
  color: #bbbbbb !important;
}

.progress-bar {
  margin-top: 20px;
  background-color: #1c1b1d;
  border: 1px solid rgba(255, 255, 255, 0.137);
  border-radius: 10px;
  position: relative;
  height: 10px;
}

.progress-bar-inner {
  background: linear-gradient(99deg, 
    #f0f0f0 0%, 
    #d3d3d3 15%, 
    #b8b8b8 40%, 
    #b9b9b9 60%, 
    #bbbbbb 75%, 
    #c7c7c7 90%, 
    #d8d8d8 100%);
  height: 100%;
  border-radius: 10px;
}

.progress-scale {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  font-size: 12px;
  color: #999999;
}


/* Upper text section */
.upper-text {
  padding: 20px;
  text-align: center;
}

/* Filter section */
.filter-section {
  margin-top: 20px;
}

.filter-section .stats {
  display: flex;
  justify-content: space-around;
  margin: 20px 0;
}

.filter-section .stats span {
  display: inline-block;
  margin: 0 10px;
  font-size: 16px;
}

.filter-section .stats .wins img,
.filter-section .stats .losses img,
.filter-section .stats .win-rate img {
  width: 24px;
  vertical-align: middle;
}

/* Signály section */
#application {
  display: flex;
  flex-direction: column;
  padding: 20px;
}

.signal-entry {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #f5f5f5;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  margin-bottom: 10px;
}

.signal-entry span {
  flex: 1;
  text-align: left;
}

.signal-entry .coin-name {
  font-weight: bold;
}

.signal-entry .result-profit {
  color: green;
}

.signal-entry .result-loss {
  color: red;
}

/* Show more button */
.showMore {
  display: block;
  margin: 20px auto;
  margin-bottom: 120px;
  padding: 10px 20px;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
}

.showMore:hover {
  background-color: #e7e5f5;
  color: #000;
  scale: 1.1;
}

/* Alert Box */
.alertBox {
  background-color: #181818b7;
  color: rgb(255, 255, 255);
  padding: 15px;
  margin: 10px 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.382) inset;
  filter: drop-shadow(0px 0px 15px rgba(0, 0, 0, 0.862));
  border-radius: 25px;
  text-align: center;
  font-size: 16px;
  position: fixed;
  font-weight: 400;
  backdrop-filter: blur(5px);
  bottom: 20px; /* Spacing from the bottom */
  right: 20px;  /* Spacing from the right */
  width: 10%;
  height: 50px;
  max-width: 500px;
  z-index: 1000;
  animation: slideIn2 0.5s ease-in-out, slideOut 0.5s ease-in-out 2.5s;
}


@keyframes slideIn2 {
  from {
    transform: translate(100%, 100%); /* Slide from the bottom-right corner */
    opacity: 0;
  }
  to {
    transform: translate(0, 0); /* Final position */
    opacity: 1;
  }
}




/* DataEntry container */
.dataEntry {
  display: flex;
  flex-direction: column;
  background-color: #120c1d;
  padding: 10px; /* Menší padding */
  margin-bottom: 10px; /* Menší mezera mezi signály */
  color: white;
  border-radius: 25px;
  max-width: 100%; /* Roztáhni šířku na celý kontejner */
  font-size: 12px; /* Zmenši písmo */
  line-height: 1.4; /* Nastav přiměřený řádkování */

  opacity: 0; /* Začneme s neviditelným prvkem */
  transform: translateY(20px); /* A trochu ho posuneme dolů */
  animation: fadeInUp3 0.3s ease-out forwards; /* Spuštění animace */
}

@keyframes fadeInUp3 {
  0% {
    opacity: 0;
    transform: translateY(20px); /* Výchozí posun */
  }
  100% {
    opacity: 1;
    transform: translateY(0); /* Přirozená pozice */
  }
}

/* Created at section */
.created_at {
  display: none;
  font-size: 12px;
  color: #c5c5c5;
  margin-bottom: 10px;
}

/* Flex containers for rows */
.dataEntry_div1,
.dataEntry_div2 {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

/* Button styles */
.copyButton,
.buttonin {
  display: flex;
  align-items: center;
  background: linear-gradient(to right, #dfdfdf, #c9c9c9);
  color: rgb(0, 0, 0) !important;
  border: none;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  cursor: pointer;
}


.h2in {
  font-size: 12px;
  color: #ffffff;
  margin-bottom: 5px;
  font-weight: 600;
}

.h3in {
  color: #000000;
  font-weight: 400;
  text-align: center;
}

.copy {
  width: 15px;
}

/* Conditional styling for single row result */
.singleRow {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  display: none;
}

.img_logo {
  align-self: flex-end;
  width: 50px;
  height: auto;
  margin-top: 10px;
}

/* General layout styling */
.container {
  display: flex;
  flex-direction: column;
  align-items: flex-start; /* Align items to the left */
  padding: 20px;
}

.dataEntry:first-child {
  margin-top: 0;
}

.dataEntry:last-child {
  margin-bottom: 0;
}


.alertBox.hide {
  opacity: 0;
  transition: opacity 0.5s;
}

@keyframes fadein {
  from { opacity: 0; }
  to { opacity: 1; }
}


.copyButton {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  background-color: #dad5e9;
  border-radius: 25px;
  width: 200px;
  margin: auto;
  cursor: pointer;
  transition: all 0.3s ease;
}

.copy {
  width: 1px;
  height: 1px;
}


.copyButton:hover {
  scale: 1.05;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.4);
}

.copyButton img {
  margin-left: 10px;
}

.h3in {
  text-align: center;
}

.buttonin {
  padding: 10px 20px;
  background: white;
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  color: white;
  border-radius: 25px;
  cursor: pointer;
  transition: background-color 0.2s;
}


.img_logo {
  width: 50px;
  height: auto;
}

/* Alert Box Animace */
@keyframes slideIn {
  0% {
    transform: translateX(100%) scale(0.5);
    opacity: 0;
  }
  100% {
    transform: translateX(0) scale(1);
    opacity: 1;
  }
}

/* Pop-Up Styles */
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 95%;
  padding: 20px;
  background-color: #000;
  color: #fff;
  border-radius: 25px;
  z-index: 9999;
  animation: zoomIn 0.3s ease-in-out;
}

/* Select Input Styles */
.select-input {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #333;
  color: #fff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}


.dash {
  height: 100vh; /* Výška dashboardu bude přesně 100% viewportu */
  display: grid;
  grid-template-columns: repeat(4, 1fr); /* 4 sloupce */
  grid-template-rows: 1fr 0.5fr 1fr;    /* 3 řádky, menší mezera mezi řádky */
  gap: 10px;
}

.dash .signals {
  grid-column: 1 / 2; /* První sloupec */
  grid-row: 1 / 4;    /* Všechny tři řádky */
  overflow-y: hidden; /* Skryj horizontální přetečení */
}

.signals {
  height: 85vh;
  width: 450px;
  padding: 20px;
  border-radius: 25px;
  text-align: center;

  /* Colors and background */
  background: linear-gradient(135deg, rgba(0, 0, 0, 0.966), rgba(0, 0, 0, 0.973), rgba(8, 8, 8, 0.966), rgba(4, 5, 7, 0.945), rgba(4, 4, 5, 0.966)); /* Gradient with blue and pink tint */
  backdrop-filter: blur(10px); /* Backdrop blur effect */
  -webkit-backdrop-filter: blur(10px); /* Safari support */
  border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for separation */

  /* Box shadow */
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.17); /* Deeper shadow effect */

  /* Gradient text and heading styling */
  color: #fff; /* Default text color */
}


.inputin {
  margin: 5px;
  padding: 5px 10px;
  border: none;
  background-color: #21212b;
  color: white;
  border-radius: 25px;
  width: 150px;
}



.inputin2 {
  margin: 5px;
  padding: 5px 10px;
  margin-left: 130px;
  border: none;
  background-color: #ffffff;
  color: rgb(0, 0, 0);
  font-weight: 600;
  border-radius: 25px;
  width: 150px;
  transition: all 0.1s ease-in-out;
}

.inputin2:hover {
  transform: scale(1.05);
  background: #161616;
  color: #fff;
  transform: scale(1.01);
  border: 0.5px solid rgba(255, 255, 255, 0.288);
}

#sidebar {
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: 0.5px solid white;
}

.stats-table {
  margin-left: 60px;
  margin-right: 60px;
   /* Colors and background */
   background: linear-gradient(135deg, rgba(0, 0, 0, 0.966), rgba(0, 0, 0, 0.973), rgba(8, 8, 8, 0.966), rgba(4, 5, 7, 0.945), rgba(4, 4, 5, 0.966)); /* Gradient with blue and pink tint */
   backdrop-filter: blur(10px); /* Backdrop blur effect */
   -webkit-backdrop-filter: blur(10px); /* Safari support */
   border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for separation */
 
   /* Box shadow */
   box-shadow: 0 8px 8px rgba(0, 0, 0, 0.17); /* Deeper shadow effect */
  padding: 20px;
  border-radius: 25px !important;
  height: 200px;
  width: 600px;
  margin-top: -45px;
  text-align: center;
}


.stats-header {
  display: flex;
  flex-direction: row; /* Horizontal alignment */
  justify-content: space-around; /* Space out each stat */
  align-items: center; /* Center align vertically */
}

.stat strong {
  display: block;
  color: #ffffff; /* Color for numbers */
  margin-bottom: 5px; /* Space between label and value */
}

.time-range {
  display: flex;
  justify-content: center;
}

.time-range button {
  background: #161616;
  color: #d3d4e0;
  border: none;
  padding: 10px;
  font-size: 11px;
  margin: 5px;
  border-radius: 25px !important;
  cursor: pointer;
  transition: background 0.3s ease;
}






.time-range button:hover {
  background: #14151d;
}

.time-range .active {
  background: linear-gradient(90deg, #f5f5f5, rgb(255, 255, 255), #dddddd);
  color: rgb(0, 0, 0);
  border: 1px solid rgba(0, 0, 0, 0.473); /* Light border for separation */
} 









/* Crypto news marquee */
.crypto-news {
   /* Colors and background */
   background: linear-gradient(135deg, rgba(0, 0, 0, 0.966), rgba(0, 0, 0, 0.973), rgba(8, 8, 8, 0.966), rgba(4, 5, 7, 0.945), rgba(4, 4, 5, 0.966)); /* Gradient with blue and pink tint */
   backdrop-filter: blur(10px); /* Backdrop blur effect */
   -webkit-backdrop-filter: blur(10px); /* Safari support */
   border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for separation */
 
   /* Box shadow */
   box-shadow: 0 8px 8px rgba(0, 0, 0, 0.17); /* Deeper shadow effect */
  color: rgb(0, 0, 0);
  padding: 15px;
  margin-top: 10px !important;
  margin: auto;
  border-radius: 25px;
  max-width: 92%;
}

.news {
  width: 100%;
  overflow: hidden;
}

.crypto-news {
  display: flex;
}

.news-item {
  display: inline-block;
  margin-right: 20px;
  padding: 15px;
  background: linear-gradient(145deg, #cbcbcf, #b3b3bb);
  border-radius: 25px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  white-space: nowrap;
}

.news-item span {
  color: #000 !important;
}

.newsbutton {
  background: linear-gradient(145deg, #ffffff, #dddddd);
  color: #000;
  border-radius: 25px;
  padding: 8px;
  width: 100px;
  border: none;
  margin-left: 15px;
  transition: all 0.3s ease;
}

.newsbutton:hover {
scale: 0.90;
border: solid 0.5px white;
}

.off {
  background: linear-gradient(to right, #292929a9, #18181888) !important;
  border: none;
  box-shadow: 0 8px 64px rgba(0, 0, 0, 0.50) inset; /* Deeper shadow effect */
  color: #2c2c2c; /* Grey text for unselected buttons */
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  margin: 5px 0;
  width: 150px;
  border-radius: 25px;
  cursor:not-allowed;
  transition: all 0.3s ease;
  transition: 0.5s;
}


.navmain .support {
  background: linear-gradient(to right, #000000d2, #000000d2);
  border: none;
  color: #888; /* Grey text for unselected buttons */
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  margin: 5px 0;
  width: 150px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  transition: 0.5s;
}

.navmain .actived {
  background: linear-gradient(to right, #000000d2, #000000d2);
  border: none;
  color: #888; /* Grey text for unselected buttons */
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  margin: 5px 0;
  width: 150px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  transition: 0.5s;
}

.navmain .support {
  background: linear-gradient(to right, #000000d2, #000000d2);
  border: none;
  color: #888; /* Grey text for unselected buttons */
  font-size: 16px;
  font-weight: 600;
  padding: 15px;
  margin: 5px 0;
  width: 150px;
  border-radius: 25px;
  cursor: pointer;
  transition: all 0.3s ease;
  transition: 0.5s;
}


.actived {
  filter: drop-shadow(0px 0px 25px rgba(0, 0, 0, 0.462));
  padding: 12px;
  font-family: "Roboto", sans-serif !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  background: #1b1b1b !important;
  animation: h1anim 0.5s ease-in-out !important;
  transition: all 0.3s ease;
  background: #161616;
  color: #fff !important;
  border: 0.5px solid rgba(255, 255, 255, 0.288) !important;

}


.navmain .support:hover {
  transform: scale(1.05);
  background: transparent !important;
  color: #fff;
  border: 0.5px solid rgba(255, 255, 255, 0.288);
}



.tradingview
 {
  margin-left: 60px;
  margin-right: 70px;
   /* Colors and background */
   background: linear-gradient(135deg, rgba(0, 0, 0, 0.966), rgba(0, 0, 0, 0.973), rgba(8, 8, 8, 0.966), rgba(4, 5, 7, 0.945), rgba(4, 4, 5, 0.966)); /* Gradient with blue and pink tint */
   backdrop-filter: blur(10px); /* Backdrop blur effect */
   -webkit-backdrop-filter: blur(10px); /* Safari support */
   border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for separation */
 
   /* Box shadow */
   box-shadow: 0 8px 8px rgba(0, 0, 0, 0.17); /* Deeper shadow effect */
  padding: 20px;
  border-radius: 25px;
  width: 600px;
  text-align: center;
}

.navmain {
  margin-left: 60px;
   /* Colors and background */
   background: linear-gradient(135deg, rgba(0, 0, 0, 0.966), rgba(0, 0, 0, 0.973), rgba(8, 8, 8, 0.966), rgba(4, 5, 7, 0.945), rgba(4, 4, 5, 0.966)); /* Gradient with blue and pink tint */
   backdrop-filter: blur(10px); /* Backdrop blur effect */
   -webkit-backdrop-filter: blur(10px); /* Safari support */
   border: 1px solid rgba(255, 255, 255, 0.2); /* Light border for separation */
 
   /* Box shadow */
   box-shadow: 0 8px 8px rgba(0, 0, 0, 0.17); /* Deeper shadow effect */
  padding: 20px;
  border-radius: 25px;
  width: 200px;
  text-align: center;
}

.dash .tradingview {
  grid-column: 2 / 3; /* Druhý sloupec */
  grid-row: 1 / 2;    /* První řádek */
}

.dash .overview {
  grid-column: 3 / 4; /* Třetí sloupec */
  grid-row: 1 / 2;    /* První řádek */
}

.dash .navmain {
  grid-column: 4 / 5; /* Čtvrtý sloupec */
  grid-row: 1 / 2;    /* První řádek */
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}



.dash .news {
  grid-column: 2 / 5; /* Sloupce 2 až 4 */
  grid-row: 2 / 3;    /* Druhý řádek */
}

.dash .stats-table {
  grid-column: 2 / 4; /* Sloupce 2 až 3 */
  grid-row: 3 / 4;    /* Třetí řádek */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: #2d2d3a; /* Tmavší pozadí */
  padding: 20px;
  border-radius: 10px;
  margin-bottom: 20px !important;
}

.dash .empty-space {
  grid-column: 4 / 5; /* Volný prostor ve třetím řádku */
  grid-row: 3 / 4;
}

.stats-header {
  display: flex;
  justify-content: space-between;
}

.time-range {
  display: flex;
  background-color: #e9e9e9;
  padding: 10px;
  border-radius: 25px;
  justify-content: space-around;
}

.time-range button {
  background: #000000ec;
  color: white;
  border: none;
  width: 50px !important;
  padding: 6px;
  border-radius: 5px;
  cursor: pointer;
  transition: all 0.3s ease-in;
}

.time-range button:hover {
  transform: scale(1.05);
  color: #fff;
  background: #000000d7;
  border: 0.5px solid rgba(255, 255, 255, 0.288);
}



.select-input:hover {
  box-shadow: rgba(255, 200, 255, 0.25) 0px 50px 100px -20px inset;
}

option {
  color: white;
  background-color: rgb(46, 46, 46);
}

/* Button Hover Effects */
.appbutton:hover {
  background: linear-gradient(90deg, rgb(15, 15, 15), rgb(40, 9, 75));
  color: #ecaff8;
}

/* Media Query for hiding the section on larger screens */
@media only screen and (min-width: 764px) {
  .sectionStyle {
    display: none;
  }
}

@media (max-width: 768px) {
  body {
    font-size: 14px;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0;
    padding: 0;
  }

  .dash {
    display: flex;
    flex-direction: column; /* Jednosloupcové rozložení */
    gap: 15px; /* Mezera mezi sekcemi */
    width: 100%;
  }

  .signals {
    display: block; /* Zobrazení sekce */
    visibility: visible; /* Ujistí se, že není skrytá */
    opacity: 1; /* Plná viditelnost */
    position: relative; /* Přirozené umístění */
    top: 0; /* Umístění sekce na začátek */
    width: 100%; /* Plná šířka */
    height: auto; /* Přizpůsobení obsahu */
    background: linear-gradient(135deg, rgba(0, 0, 0, 0.6), rgba(8, 8, 8, 0.6)); /* Atraktivní pozadí */
    border-radius: 15px; /* Zaoblené rohy */
    padding: 20px;
    text-align: center;
    margin-bottom: 20px; /* Spodní mezera */
    z-index: 10; /* Přední vrstva */
  }

  .tradingview {
    display: none; /* Skrytí grafu */
  }

  .overview {
    width: 100%; /* Plná šířka */
    height: auto; /* Přizpůsobení obsahu */
    margin-bottom: 20px;
    text-align: center;
  }

  .stats-table {
    width: 100%; /* Plná šířka */
    margin: 0 auto; /* Vycentrování sekce */
    padding: 15px;
    height: auto; /* Dynamická výška */
    margin-bottom: 20px !important;
  }

  .dash .stats-table {
    margin-bottom: 20px !important;
  }

  .showMore:hover {
    background-color: #000;
    color: white;
  }

  .signals-container {
    border-radius: 10;
  }

  .signals-container {
    padding-right: 0;
  }

  .copyButton, .buttonin {
    display: block;
  }

  .stats-header {
    align-items: center;
    text-align: center;
    gap: 10px;
    margin-bottom: 10px;
  }

  .stat strong {
    color: #ffffff !important;
    font-size: medium;
    font-weight: 600;
  }

  .stats-header .stat {
    font-size: 24px; /* Přizpůsobení velikosti pro mobilní zařízení */
  }

  .news {
    display: flex;
    flex-direction: column; /* Vertikální rozvržení novinek */
    padding: 10px;
    width: 100%; /* Plná šířka */
  }

  .news-item {
    margin-bottom: 10px; /* Mezera mezi jednotlivými novinkami */
    width: 100%; /* Úprava šířky */
  }

  .crypto-news {
    flex-wrap: wrap; /* Zalomení prvků */
    justify-content: center;
  }

  .navbar {
    width: 100%; /* Plná šířka */
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 10px;
  }

  .navbar li {
    margin: 0; /* Zrušení okrajů */
  }

  .navbar a {
    font-size: 14px; /* Menší text pro mobilní zařízení */
  }

  .upper_background {
    padding: 15px;
    text-align: center;
  }

  .filter-section {
    padding: 10px;
    flex-direction: column; /* Vertikální rozvržení filtrů */
    align-items: center;
    justify-content: center;
  }

  .inputin, .inputin2 {
    width: 100%; /* Plná šířka pro vstupy */
    margin: 5px 0;
  }

  .showMore {
    padding: 10px;
    width: 90%; /* Plná šířka tlačítka */
    margin: 10px auto; /* Vycentrování */
  }

  .alertBox {
    width: 40%; /* Přizpůsobení velikosti */
    left: 55%; /* Vycentrování horizontálně */
    bottom: 10%; /* Spodní okraj */
  }

  .popup {
    width: 90%; /* Přizpůsobení popupu */
    padding: 15px;
  }

  .copyButton {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: #ffffff !important;
    color: #000 !important;
    border-radius: 25px;
    width: 250px;
    margin: auto;
    cursor: pointer;
    transition: all 0.3s ease;
  }

  .progress-scale {
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 0;
  }

  #sidebar {
    border: none;
  }
  .upper_background {
    background-image: none;
    background-color: white !important;
  }

  .overview {
    background: linear-gradient(135deg, rgb(15, 15, 15), rgba(14, 13, 13, 0.986)); /* Atraktivní styl pozadí */
  }

  .navmain {
    display: none !important;
  }

  .dash .news {
    display: none !important;
  }

  .dash .stats-table {
    background: linear-gradient(135deg, rgb(15, 15, 15), rgba(14, 13, 13, 0.986)); /* Atraktivní styl pozadí */
    margin-bottom: 20px !important;
  }

  .overh2 {
    color: #ffffff !important;
    margin-bottom: 10px !important;
    font-size: small;
  }

  .description {
    margin-bottom: 10px !important;
  }

  .overview span {
    font-size: medium;
  }

  .dash .signals {
    display: block; /* Ujistí se, že sekce je viditelná */
    overflow-y: hidden; /* Povolení vertikálního scrollování */
    max-height: 70vh; /* Maximální výška na 70 % výšky viewportu */
    margin-bottom: 20px;
    width: 100%;
    padding-bottom: 900px;
    background: linear-gradient(135deg, rgb(15, 15, 15), rgba(14, 13, 13, 0.986)); /* Atraktivní styl pozadí */
    border-radius: 15px; /* Zaoblené rohy */
    text-align: center;
  }

  .signals {
    overflow-y: auto; /* Povolení vertikálního scrollování */
  }

  .dash {
    display: flex;
    flex-direction: column; /* Jednosloupcové rozložení */
    gap: 15px;
  }

  .signals-container::-webkit-scrollbar {
    width: 8px; /* Šířka scrollbar na mobilu */
  }

  .signals-container::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.4); /* Barva scrollbaru */
    border-radius: 10px; /* Zaoblený scrollbar */
  }

  .signals-container::-webkit-scrollbar-track {
    background-color: rgba(0, 0, 0, 0.2); /* Barva pozadí scrollbaru */
  }

  .time-range {
    flex-wrap: wrap; /* Možnost zalamování tlačítek */
    justify-content: center; /* Vycentrování tlačítek */
    gap: 10px;
    padding: 10px;
  }

  .time-range button {
    width: auto; /* Dynamická šířka */
    font-size: 12px;
  }
}
